@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.shadow {
  box-shadow: 0px 24px 43px 0px #4c4c4c12;
}
label {
  color: #101828;
}
.verifyInput {
  width: 59.17px;
}
.input {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 10px 14px 10px 14px;
  width: 420px;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
  visibility: hidden;
}
.input:focus {
  outline: none;
}
.nameInput {
  border: 1px solid #d0d5dd;
  width: 200px;
  border-radius: 12px;
  padding: 10px 14px 10px 14px;
}
.shadow {
  box-shadow: 0px 0px 43px 12px #4c4c4c12;
}
.label {
  color: #101828;
  font-size: 12px;
}
.verifyInput {
  width: 59.17px;
}
.hide-eye-icon::-webkit-reveal,
.hide-eye-icon::-webkit-clear {
  display: none;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.input {
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding: 10px 14px 10px 14px;
  width: 420px;
}
.shadow {
  box-shadow: 0px 24px 43px 0px #4c4c4c12;
}
label {
  color: #101828;
}
.verifyInput {
  width: 59.17px;
}
.input {
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding: 10px 14px 10px 14px;
  width: 420px;
}
.nameInput {
  border: 1px solid #d0d5dd;
  width: 200px;
  border-radius: 12px;
  padding: 10px 14px 10px 14px;
}
.shadow {
  box-shadow: 0px 0px 43px 12px #4c4c4c12;
}
.label {
  color: #101828;
  font-size: 12px;
}
.verifyInput {
  width: 59.17px;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* input{
  border: 1px solid #D0D5DD;
  border-radius:12px;
  padding: 10px 14px 10px 14px;
  width: 420px;
} */
.shadow {
  box-shadow: 0px 24px 43px 0px #4c4c4c12;
}
label {
  color: #101828;
}
.verifyInput {
  width: 59.17px;
}
.input {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 10px 14px 10px 14px;
  width: 420px;
}
.input:focus {
  outline: none;
}
.nameInput {
  border: 1px solid #d0d5dd;
  width: 200px;
  border-radius: 12px;
  padding: 10px 14px 10px 14px;
}
.shadow {
  box-shadow: 0px 0px 43px 12px #4c4c4c12;
}
.label {
  color: #101828;
  font-size: 12px;
}
.verifyInput {
  width: 59.17px;
}
.hide-eye-icon::-webkit-reveal,
.hide-eye-icon::-webkit-clear {
  display: none;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

input {
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding: 10px 14px 10px 14px;
  width: 420px;
}
.shadow {
  box-shadow: 0px 24px 43px 0px #4c4c4c12;
}
label {
  color: #101828;
}
.verifyInput {
  width: 59.17px;
}
.input {
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding: 10px 14px 10px 14px;
  width: 420px;
}
.nameInput {
  border: 1px solid #d0d5dd;
  width: 200px;
  border-radius: 12px;
  padding: 10px 14px 10px 14px;
}
.shadow {
  box-shadow: 0px 0px 43px 12px #4c4c4c12;
}
.label {
  color: #101828;
  font-size: 12px;
}
.verifyInput {
  width: 59.17px;
}

ul.UlListStyle {
  list-style: disc !important;
  color: #7f7f81;
  font-size: 14px;
}
/* chat styling  */
/* Headings */
.chatbox h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

.chatbox h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

.chatbox h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* Paragraphs */
.chatbox p {
  margin-bottom: 1em;
}

/* Lists */
.chatbox ul,
.chatbox ol {
  margin-top: 1em;
  margin-bottom: 1em;
}

.chatbox li {
  margin-bottom: 0.5em;
}

/* Blockquotes */
.chatbox blockquote {
  margin-left: 1em;
  padding-left: 1em;
  border-left: 2px solid #ccc;
}

/* Code blocks */
.chatbox pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5em;
  overflow: auto;
}

.chatbox code {
  font-family: monospace;
}

/* Links */
.chatbox a {
  color: #0366d6; /* Default link color */
  text-decoration: none; /* Remove underline */
}

.chatbox a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  height: 100%;
}

.customerHeader {
  @apply font-medium text-sm text-BLACK-_300;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollContainer::-webkit-scrollbar {
  width: 3px;
}
.scrollContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scrollContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ck.ck-editor__main > div {
  padding-left: 20px; /* Adjust the padding as needed */
}
.prose {
  max-width: 100%;
}
.prose * {
  font-size: 14px;
}
