* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Figtree", sans-serif;
}

.wano-bg {
  background: rgba(255, 255, 255, 0.38);
  backdrop-filter: blur(2px);
}

.gradient-border {
  border-radius: 12px;
  background: linear-gradient(to right, #ca62ef, #4b92fb, #f6c0d1);
}

.loader {
  background-image: url(../src/Assets/gif/loadingAnimation.gif);
  background-size: cover;
  width: 100%;
  height: 100px;
  mix-blend-mode: multiply;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-upload-wrapper .ant-upload-drag .ant-upload{
  padding: 0px;
}
#root > div.flex.h-screen.relative > div > div:nth-child(2) > section > section > section > section > section > div > main > section > div.mt-2 > span > div.ant-upload-list.ant-upload-list-text{
  display: none;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-upload-wrapper
  .ant-upload-drag {
  background-color: white;
  border: none;
  border-radius: 4px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
.fixed-width-table {
  width: 100%;
  table-layout: fixed;
}

.fixed-width-table th {
  text-align: left; /* Adjust as needed */
  background-color: #F6F8FA;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  font-size: 0.875rem;
  color: #7f7f81;
  font-weight: 600;
}

.fixed-width-table th:nth-child(1) {
  width: 35%; /* Adjust as needed */
  border-top-right-radius: 0.5rem;
  padding-left: 20px;

}

.fixed-width-table th:nth-child(2) {
  width: 15%; /* Adjust as needed */
  padding-left: 10px;
}
.fixed-width-table td:nth-child(2) {
  width: 25%; /* Adjust as needed */
  padding-left: 10px;
}

.fixed-width-table th:nth-child(3) {
  width: 15%; /* Adjust as needed */
}

.fixed-width-table th:nth-child(4) {
  width: 15%; /* Adjust as needed */
}
.fixed-width-table th:nth-child(5) {
  width: 10%; /* Adjust as needed */
}
.fixed-width-table th:nth-child(6) {
  width: 5%; /* Adjust as needed */
}

.plugin-gradient {
  background: linear-gradient(12deg, rgba(248, 196, 211, 0.15) 2%, rgba(23, 116, 253, 0.02) 50%);
}

.hide-scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: #ffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: #EAECF0;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.hide-scrollbar::-webkit-scrollbar-button {
  background-color: #fff;
  height: 2px;
  /* -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%; */ 
}

.hide-scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.hide-scrollbar::-webkit-scrollbar-track-piece:start:end:corner {

}

.hide-scrollbar::-webkit-scrollbar-thumb::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovered */
}

select{
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
    appearance: none;
  padding:1px 20px 1px 1px;

}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  visibility: hidden;
  width: fit-content;
  background-color: #fcfcfc;
  /* color: gray; */
  /* font-size: smaller; */
  /* text-align: center; */
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 2;
  left: 120%; 
  top: 50%;
  transform: translateY(-50%);
  /* white-space: nowrap; */
  /* margin-left: -60px; */
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  height: 100%;
}
.ck.ck-editor {
  border: 2px solid red
}
